/* eslint-disable arrow-body-style */
// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconCash, IconReceipt } from '@tabler/icons';
import ReceiptIcon from '@mui/icons-material/Receipt';
import FeedIcon from '@mui/icons-material/Feed';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PaidIcon from '@mui/icons-material/Paid';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
// import jwtDecode from 'jwt-decode';

// constant
const icons = {
    IconCash,
    IconReceipt,
    ReceiptIcon,
    FeedIcon,
    ListAltIcon,
    PaidIcon,
    ReceiptLongIcon,
    RecentActorsIcon
};

interface ITenantTypeDecode {
    tenantTypeId: number;
    recordType: number[];
}
interface ITenantDecode {
    tenantId: number;
    tenantTypes: ITenantTypeDecode[];
}
export interface IUserDecode {
    userId: number;
    fireBaseId: string;
    tenants: ITenantDecode[];
    iat: number;
    exp: number;
    [key: string]: any;
}
// ==============================|| APPLICATION MENU ITEMS ||============================== //

const accounting = () => {
    // const token = localStorage.getItem('backend_jwt') || '';
    // const user: IUserDecode = jwtDecode(token);

    // TODO: Use the tenant id fetched and not the hardcode one
    // const recordTypes = user.tenants
    //     .find((tenant) => tenant.tenantId === 1)
    //     ?.tenantTypes.find((type) => type.tenantTypeId === 1)?.recordType;
    // console.log('user decode: ', user);

    return {
        id: 'accounting',
        title: <FormattedMessage id="accounting" />,
        type: 'group',
        children: [
            {
                id: 'payable',
                title: <FormattedMessage id="payable" />,
                type: 'collapse',
                icon: icons.IconCash,
                children: [
                    {
                        id: 'bills',
                        title: <FormattedMessage id="bills" />,
                        recordId: 3,
                        type: 'item',
                        icon: icons.IconReceipt,
                        url: 'accounting/payable/bills'
                    },
                    {
                        id: 'docViewer',
                        title: <FormattedMessage id="docViewer" />,
                        recordId: 3,
                        type: 'item',
                        icon: icons.IconReceipt,
                        url: 'accounting/doc-viewer'
                    },
                    {
                        id: 'Credit Memos',
                        title: <FormattedMessage id="Credit Memos" />,
                        recordId: 3,
                        type: 'item',
                        icon: icons.ReceiptIcon,
                        url: 'accounting/payable/bills'
                    },
                    {
                        id: 'Vendor Statement',
                        title: <FormattedMessage id="Vendor Statement" />,
                        recordId: 3,
                        type: 'item',
                        icon: icons.FeedIcon,
                        url: 'accounting/payable/bills'
                    },
                    {
                        id: 'Proforma',
                        title: <FormattedMessage id="Proforma" />,
                        recordId: 3,
                        type: 'item',
                        icon: icons.ListAltIcon,
                        url: 'accounting/payable/bills'
                    },
                    {
                        id: 'W9',
                        title: <FormattedMessage id="W9" />,
                        recordId: 3,
                        type: 'item',
                        icon: icons.PaidIcon,
                        url: 'accounting/payable/bills'
                    }
                ]
            },
            {
                id: 'Receivable',
                title: <FormattedMessage id="Receivable" />,
                type: 'collapse',
                icon: icons.RecentActorsIcon,
                children: [
                    {
                        id: 'Customer Invoice',
                        title: <FormattedMessage id="Customer Invoice" />,
                        recordId: 3,
                        type: 'item',
                        icon: icons.ReceiptIcon,
                        url: 'accounting/payable/bills'
                    },
                    {
                        id: 'Customer Statement',
                        title: <FormattedMessage id="Customer Statement" />,
                        recordId: 3,
                        type: 'item',
                        icon: icons.ReceiptLongIcon,
                        url: 'accounting/payable/bills'
                    }
                ]
            }
        ]
    };
};

export default accounting;
