import { FormattedMessage } from 'react-intl';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';

const icons = {
    LocalShippingIcon,
    AssignmentLateIcon,
    WarehouseIcon,
    AirplaneTicketIcon
};

const procurement = {
    id: 'Procurement',
    title: <FormattedMessage id="Procurement" />,
    type: 'group',
    children: [
        {
            id: 'Acknowledgement',
            title: <FormattedMessage id="Acknowledgement" />,
            type: 'item',
            icon: icons.AssignmentLateIcon,
            url: 'procurement/acknowledgement'
        },
        {
            id: 'Shipping Notice',
            title: <FormattedMessage id="Shipping Notice" />,
            type: 'item',
            icon: icons.LocalShippingIcon,
            url: 'backoffice/users'
        },
        {
            id: 'Delivery Ticket',
            title: <FormattedMessage id="Delivery Ticket" />,
            type: 'item',
            icon: icons.WarehouseIcon,
            url: 'backoffice/users'
        },
        {
            id: 'Warehouse Receiving',
            title: <FormattedMessage id="Warehouse Receiving" />,
            type: 'item',
            icon: icons.AirplaneTicketIcon,
            url: 'backoffice/users'
        }
    ]
};

export default procurement;
