import { NavItemType } from 'types';
import { IconTriangleSquareCircle, IconToggleLeft, IconSubtask, IconClock, IconTemplate, IconFileInvoice } from '@tabler/icons';
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';
import MmsIcon from '@mui/icons-material/Mms';
// TODO: Change title to i18n
const poetMenuItems: NavItemType[] = [
    {
        id: 'Products',
        title: 'Products',
        type: 'label'
    },
    {
        id: 'products',
        title: 'Products',
        type: 'item',
        icon: IconTriangleSquareCircle,
        url: 'products'
    },
    {
        id: 'attributes',
        title: 'Attributes',
        type: 'item',
        icon: IconToggleLeft,
        url: 'attributes'
    },
    {
        id: 'product-templates',
        title: 'Templates',
        type: 'item',
        icon: IconTemplate,
        url: 'product-templates'
    },
    {
        id: 'labes',
        title: 'Labels',
        type: 'item',
        icon: BeenhereOutlinedIcon,
        url: 'labels'
    },
    {
        id: 'file-headers',
        title: 'File Headers',
        type: 'item',
        icon: IconFileInvoice,
        url: 'file-headers'
    },
    {
        id: 'Channels',
        title: 'Channels',
        type: 'label'
    },
    {
        id: 'channels',
        title: 'Channels',
        type: 'item',
        icon: IconSubtask,
        url: 'channels'
    },
    {
        id: 'Assets',
        title: 'Assets',
        type: 'label'
    },
    {
        id: 'assets',
        title: 'All Assets',
        type: 'item',
        icon: MmsIcon,
        url: 'assets'
    },
    {
        id: 'Logs',
        title: 'Logs',
        type: 'label'
    },
    {
        id: 'logs',
        title: 'Logs',
        type: 'item',
        icon: IconClock,
        url: 'logs'
    }
];

export default poetMenuItems;
