import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { isPoetProject } from 'utils';

// sample page routing
const DashboardPage = Loadable(lazy(() => import('views/dashboard')));
const BackofficeUsers = Loadable(lazy(() => import('views/backoffice/users')));
const BackofficeTenantUsers = Loadable(lazy(() => import('views/backoffice/tenantUsers')));
const BackofficeRoles = Loadable(lazy(() => import('views/backoffice/roles')));
const BackofficeRoleAliases = Loadable(lazy(() => import('views/backoffice/roleAliases')));
const Bills = Loadable(lazy(() => import('views/accounting/payable/bills')));
const Acknowledgement = Loadable(lazy(() => import('views/Acknowledgement')));
const DocumentViewer = Loadable(lazy(() => import('views/DocumentViewer')));

// poet pages
const PoetProducts = Loadable(lazy(() => import('views/poet/products/index')));
const PoetLabels = Loadable(lazy(() => import('views/poet/labels/index')));
const PoetProductDetails = Loadable(lazy(() => import('views/poet/productDetails/index')));
const PoetProductPreview = Loadable(lazy(() => import('views/poet/productPreview/index')));
const PoetImportCsv = Loadable(lazy(() => import('views/poet/importCsv/index')));

const PoetChannels = Loadable(lazy(() => import('views/poet/channels/index')));
const PoetChannelDetails = Loadable(lazy(() => import('views/poet/channelDetails/index')));
const PoetSettings = Loadable(lazy(() => import('views/poet/attributes/index')));
const PoetLogs = Loadable(lazy(() => import('views/poet/logs/index')));
const PoetProductTemplates = Loadable(lazy(() => import('views/poet/productTemplates')));
const PoetProductTemplateDetails = Loadable(lazy(() => import('views/poet/productTemplateDetails')));
const PoetFileHeaders = Loadable(lazy(() => import('views/poet/fileHeaders')));
const PoetAssets = Loadable(lazy(() => import('views/poet/assets')));
const PoetImportAssets = Loadable(lazy(() => import('views/poet/importAssets')));
const PoetProductAssetLinking = Loadable(lazy(() => import('views/poet/productAssetsLinking')));

const ORDERBAHN_ROUTES = [
    {
        path: 'dashboard',
        element: <DashboardPage />
    },
    {
        path: 'backoffice/users',
        element: <BackofficeUsers />
    },
    {
        path: 'backoffice/tenant-users',
        element: <BackofficeTenantUsers />
    },
    {
        path: 'backoffice/roles',
        element: <BackofficeRoles />
    },
    {
        path: 'backoffice/role-aliases',
        element: <BackofficeRoleAliases />
    },
    {
        path: 'accounting/payable/bills',
        element: <Bills />
    },
    {
        path: 'procurement/acknowledgement',
        element: <Acknowledgement />
    },
    {
        path: 'accounting/doc-viewer',
        element: <DocumentViewer />
    },
    {
        path: '*',
        element: <Bills />
    }
];

const POET_ROUTES = [
    {
        path: 'import',
        element: <PoetImportCsv />
    },
    {
        path: 'products/:id',
        element: <PoetProductDetails />
    },
    {
        path: 'productPreview/:channelId/:id',
        element: <PoetProductPreview />
    },
    {
        path: 'products',
        element: <PoetProducts />
    },
    {
        path: 'labels',
        element: <PoetLabels />
    },
    {
        path: 'channels/:id',
        element: <PoetChannelDetails />
    },
    {
        path: 'channels',
        element: <PoetChannels />
    },
    {
        path: 'attributes',
        element: <PoetSettings />
    },
    {
        path: 'Logs',
        element: <PoetLogs />
    },
    {
        path: 'product-templates',
        element: <PoetProductTemplates />
    },
    {
        path: 'product-templates/:id',
        element: <PoetProductTemplateDetails />
    },
    {
        path: 'file-headers',
        element: <PoetFileHeaders />
    },
    {
        path: 'assets',
        element: <PoetAssets />
    },
    {
        path: 'importAssets',
        element: <PoetImportAssets />
    },
    {
        path: 'productAssetLinking',
        element: <PoetProductAssetLinking />
    },
    {
        path: '*',
        element: <div>Not found!</div>
    }
];

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/:tenantName',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: isPoetProject() ? POET_ROUTES : ORDERBAHN_ROUTES
};

export default MainRoutes;
