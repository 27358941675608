import { useNavigate, useParams } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { DASHBOARD_PATH, SETUP_MFA_PATH } from 'config';
import { GuardProps } from 'types';
import { useEffect } from 'react';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children, ...props }: GuardProps) => {
    const { isLoggedIn, shouldSkipSetupMFA } = useAuth();
    const navigate = useNavigate();
    const params = useParams();
    const tenantName = localStorage.getItem('tenantPath') || `/${params.tenantName}`;
    const storageshouldSkipSetupMFA = window.localStorage.getItem('shouldSkipSetupMFA');
    useEffect(() => {
        if (isLoggedIn) {
            if (!shouldSkipSetupMFA && !storageshouldSkipSetupMFA) {
                navigate(SETUP_MFA_PATH(tenantName || ''), { replace: true });
            } else {
                navigate(DASHBOARD_PATH(tenantName || ''), { replace: true });
            }
        }
    }, [shouldSkipSetupMFA, isLoggedIn, navigate, storageshouldSkipSetupMFA, tenantName]);

    return children;
};

export default GuestGuard;
