import { AlertTitle } from '@mui/material';
import { openSnackbar } from 'store/slices/snackbar';

export function openSuccessSnackbar({ title, message, cy }: { title: string; message: string; cy?: string }) {
    return openSnackbar({
        open: true,
        message: (
            <>
                <AlertTitle data-cy={cy}>{title}</AlertTitle>
                {message}
            </>
        ),
        variant: 'alert',
        alert: {
            color: 'success'
        },
        anchorOrigin: {
            vertical: 'center',
            horizontal: 'center'
        },
        close: true
    });
}
