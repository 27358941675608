import React, { ReactNode, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { QUERY_FIND_TENANT_BY_URL } from 'graphql/queries/tenants';
import { Navigate, useParams } from 'react-router-dom';

const TenantValidatorProvider = ({ children }: { children?: ReactNode | undefined }) => {
    const params = useParams();

    const { data, loading } = useQuery<{ findTenantByUrl: string }>(QUERY_FIND_TENANT_BY_URL, {
        variables: {
            url: params.tenantName
        }
    });

    const tenantId = useMemo(() => Number(data?.findTenantByUrl), [data]);

    useEffect(() => {
        if (!isNaN(tenantId)) localStorage.setItem('tenant_id', String(tenantId));
    }, [tenantId]);

    if (loading) return null;
    return isNaN(tenantId) ? <Navigate replace to="/404" /> : <>{children}</>;
};

export default TenantValidatorProvider;
