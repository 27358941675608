/* eslint-disable react/self-closing-comp */
import { forwardRef, ForwardRefExoticComponent, memo, RefAttributes, useEffect, useMemo, useState } from 'react';

// material-ui
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { QUERY_GET_MENU_ITEMS } from 'graphql/queries/menuItems';
import { useLazyQuery } from '@apollo/client';
import { useTheme } from '@mui/styles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import useConfig from 'hooks/useConfig';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import { activeItem, activeMenuItem, openDrawer, saveActionData } from 'store/slices/menu';
import { useDispatch } from 'react-redux';
import { LinkTarget } from 'types';
import { IGetItemActions } from 'types/layout';
import { QUERY_GET_MY_ITEM_ACTIONS } from 'graphql/queries/layout';
import { isPoetProject } from 'utils';
import NavItem from './NavItem';
import { borderTop } from '@mui/system';

// ==============================|| SIDEBAR MENU LIST ||============================== //

export interface IAppModule {
    id: string;
    name: string;
    tag: string;
    route: string;
    order: number;
    application: string;
    enabled: boolean;
    appSections: IAppSection[];
}

export interface IAppSection {
    id: string;
    name: string;
    tag: string;
    route: string;
    enabled: boolean;
    menuItems: IMenuItem[];
}
export interface IMenuItem {
    id: string;
    name: string;
    application: string;
    route: string;
    enabled: boolean;
    hasRecordTypes: boolean;
    recordType: IMenuRecordType;
}

export interface IMenuRecordType {
    id: string;
    name: string;
}

const MenuList = () => {
    const theme = useTheme();
    const [filteredMenuData, setFilteredMenuData] = useState<IAppModule[]>([]);
    const [loadMenuItems, { loading: menuLoading, data: menuData }] = useLazyQuery<{ SaasMenu: IAppModule[] }>(QUERY_GET_MENU_ITEMS);
    const [loadItemActions, { loading: actionsLoading, data: actionsData }] = useLazyQuery<IGetItemActions>(QUERY_GET_MY_ITEM_ACTIONS);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isPoetProject()) {
            loadMenuItems();
            loadItemActions();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!menuData || !actionsData || isPoetProject()) return;
        dispatch(saveActionData(actionsData.SaasMyRoleMenuItemActions));
        const myMenuItemsIds = actionsData?.SaasMyRoleMenuItemActions.filter((el) =>
            el.menuItemActions.find((action) => Number(action.id) === 1)
        ).map((el) => Number(el.menuItem.id));

        const filteredList: IAppModule[] = menuData?.SaasMenu.map((saasMenu) => ({
            ...saasMenu,
            appSections: saasMenu.appSections.map((appSection) => ({
                ...appSection,
                menuItems: appSection.menuItems.filter((menuItemEl) => myMenuItemsIds?.includes(Number(menuItemEl.id)))
            }))
        }));
        setFilteredMenuData(filteredList);
    }, [actionsData, dispatch, menuData]);

    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            case 'item':
                return <NavItem key={item.id} item={item} level={0} />;
            case 'label':
                return (
                    <Typography
                        key={item.id}
                        align="left"
                        sx={{ borderTop: item.id !== 'Products' ? 1 : 0, borderColor: '#D9D9D9', py: 2, fontSize: 20 }}
                    >
                        {item.title}
                    </Typography>
                );
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const saasMenuItems = useMemo(
        () =>
            !!filteredMenuData.length &&
            filteredMenuData
                .filter((item) => item.enabled)
                .map((item) => (
                    <List
                        subheader={
                            item.name && (
                                <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
                                    {item.name}
                                </Typography>
                            )
                        }
                    >
                        {item.appSections
                            .filter((section) => section.enabled)
                            .map((section) => (
                                <AppSections section={section} parentRoute={`${item.route}`} />
                            ))}
                    </List>
                )),
        [filteredMenuData, theme.typography.menuCaption]
    );

    if (menuLoading || actionsLoading) return null;

    return (
        <div style={{ marginTop: 10, padding: 2, ...(isPoetProject() ? { flex: 1 } : {}) }}>
            {navItems}
            {saasMenuItems}
        </div>
    );
};

const AppSections = ({ section, parentRoute }: { section: IAppSection; parentRoute: string }) => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const { pathname } = useLocation();

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<string | boolean | null | undefined>(null);

    const handleClick = () => {
        setOpen(!open);
        setSelected(!open ? section.id : null);
    };

    useEffect(() => {
        setSelected(pathname.includes(section.route));
    }, [pathname, section.route]);

    const menuIcon = (
        <FiberManualRecordIcon
            sx={{
                width: selected === section.id ? 8 : 6,
                height: selected === section.id ? 8 : 6
            }}
            fontSize="medium"
        />
    );

    return (
        <>
            {section.menuItems.length ? (
                <>
                    <ListItemButton
                        sx={{
                            borderRadius: `${borderRadius}px`,
                            mb: 0.5,
                            alignItems: 'flex-start',
                            // backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                            py: 1.25,
                            pl: 4
                        }}
                        selected={selected === section.id}
                        onClick={handleClick}
                    >
                        <ListItemIcon sx={{ my: 'auto', minWidth: 18 }}>{menuIcon}</ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography variant={selected === section.id ? 'h5' : 'body1'} color="inherit" sx={{ my: 'auto' }}>
                                    {section.name}
                                </Typography>
                            }
                        />
                        {open ? (
                            <IconChevronUp stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                        ) : (
                            <IconChevronDown stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                        )}
                    </ListItemButton>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {open && (
                            <List
                                component="div"
                                disablePadding
                                sx={{
                                    position: 'relative',
                                    '&:after': {
                                        content: "''",
                                        position: 'absolute',
                                        left: '32px',
                                        top: 0,
                                        height: '100%',
                                        width: '1px',
                                        opacity: theme.palette.mode === 'dark' ? 0.2 : 1,
                                        background: theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.primary.light
                                    }
                                }}
                            >
                                {section.menuItems.map((item) => (
                                    <SaasMenuItem saasItem={item} parentRoute={`${parentRoute}/${section.route}`} />
                                ))}
                            </List>
                        )}
                    </Collapse>
                </>
            ) : (
                <SaasMenuItem saasItem={section} pl={4} parentRoute={parentRoute} />
            )}
        </>
    );
};

const SaasMenuItem = ({ saasItem, pl, parentRoute }: { saasItem: IMenuItem | IAppSection; pl?: number; parentRoute: string }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { borderRadius } = useConfig();
    const { pathname } = useLocation();
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));
    const [selected, setSelected] = useState<string | boolean | null | undefined>(null);

    const itemHandler = (id: string) => {
        dispatch(activeItem([id]));
        dispatch(activeMenuItem(saasItem));
        matchesSM && dispatch(openDrawer(false));
    };

    const listItemProps: {
        component: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>> | string;
        href?: string;
        target?: LinkTarget;
    } = { component: forwardRef((props, ref) => <Link ref={ref} {...props} to={`${parentRoute}/${saasItem.route}`} target="_self" />) };

    const menuIcon = (
        <FiberManualRecordIcon
            sx={{
                width: selected === saasItem.id ? 8 : 6,
                height: selected === saasItem.id ? 8 : 6
            }}
            fontSize="medium"
        />
    );

    useEffect(() => {
        setSelected(pathname.includes(saasItem.route));
    }, [pathname, saasItem.route]);

    return (
        <ListItemButton
            {...listItemProps}
            sx={{
                borderRadius: `${borderRadius}px`,
                mb: 0.5,
                alignItems: 'flex-start',
                // backgroundColor: 'inherit',
                // py: level > 1 ? 1 : 1.25,
                pl: pl || 6
            }}
            selected={!!selected}
            onClick={() => itemHandler(saasItem.id!)}
        >
            <ListItemIcon sx={{ my: 'auto', minWidth: 18 }}>{menuIcon}</ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant={selected ? 'h5' : 'body1'} color="inherit">
                        {saasItem.name}
                    </Typography>
                }
            />
        </ListItemButton>
    );
};

export default memo(MenuList);
