// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconUser, IconUserCheck, IconBuildingWarehouse, IconKey, IconBrandCodesandbox, IconLetterCase, IconFileInfo } from '@tabler/icons';

// constant
const icons = {
    IconUser,
    IconUserCheck,
    IconBuildingWarehouse,
    IconKey,
    IconBrandCodesandbox,
    IconLetterCase,
    IconFileInfo
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const application = {
    id: 'setting',
    title: <FormattedMessage id="settings" />,
    type: 'group',
    children: [
        {
            id: 'users',
            title: <FormattedMessage id="users" />,
            type: 'item',
            icon: icons.IconUser,
            url: 'backoffice/users'
        },
        // {
        //     id: 'tenantUsers',
        //     title: <FormattedMessage id="tenantUsers" />,
        //     type: 'item',
        //     icon: icons.IconUserCheck,
        //     url: 'backoffice/tenant-users'
        // },
        {
            id: 'roles',
            title: <FormattedMessage id="roles" />,
            type: 'item',
            icon: icons.IconKey,
            url: 'backoffice/roles'
        },
        {
            id: 'rolesAliases',
            title: <FormattedMessage id="roleAliases" />,
            type: 'item',
            icon: icons.IconLetterCase,
            url: 'backoffice/role-aliases'
        }
    ]
};

export default application;
