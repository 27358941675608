import { GridFilterOperator, getGridNumericOperators, getGridStringOperators } from '@mui/x-data-grid-pro';

const numericMap: Record<string, { value: string; label: string }> = {
    '<': {
        value: 'lessThan',
        label: 'less than'
    },
    '<=': {
        value: 'lessThanEqualTo',
        label: 'less than or equal to'
    },
    '>': {
        value: 'greaterThan',
        label: 'greater than'
    },
    '>=': {
        value: 'greaterThanEqualTo',
        label: 'greater than or equal to'
    },
    '=': {
        value: 'equalTo',
        label: 'equal to'
    },
    '!=': {
        value: 'notEqualTo',
        label: 'not equal to'
    },
    isEmpty: {
        value: 'isNotDefined',
        label: "isn't defined"
    },
    isNotEmpty: {
        value: 'isDefined',
        label: 'isNotDefined'
    }
};

const stringMap: Record<string, { value: string; label: string }> = {
    isNotEmpty: {
        value: 'isDefined',
        label: 'is defined'
    },
    isEmpty: {
        value: 'isNotDefined',
        label: "isn't defined"
    },
    contains: {
        value: 'contains',
        label: 'contains'
    },
    notContains: {
        value: 'notContains',
        label: "doesn't contains"
    }
};

export const numericOperators: GridFilterOperator[] = [
    ...getGridNumericOperators()
        .filter(({ value }) => !!numericMap[value])
        .map((operator) => ({
            ...operator,
            ...numericMap[operator.value]
        }))
];

export const stringOperators: GridFilterOperator[] = [
    ...getGridStringOperators()
        .filter(({ value }) => !!stringMap[value])
        .map((operator) =>
            operator.value === 'contains'
                ? [
                      {
                          ...operator,
                          ...stringMap.contains
                      },
                      {
                          ...operator,
                          ...stringMap.notContains
                      }
                  ]
                : {
                      ...operator,
                      ...stringMap[operator.value]
                  }
        )
        .flat()
];
