import { AlertTitle } from '@mui/material';
import { openSnackbar } from 'store/slices/snackbar';

export function openErrorSnackbar({ title = 'Error!', message, cy }: { title?: string; message: string; cy?: string }) {
    return openSnackbar({
        open: true,
        message: (
            <>
                <AlertTitle data-cy={cy}>{title}</AlertTitle>
                {message}
            </>
        ),
        variant: 'alert',
        alert: {
            color: 'error'
        },
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
        },
        close: true
    });
}
