import { useNavigate, useParams } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { GuardProps } from 'types';
import { useEffect } from 'react';
import { useDispatch } from 'store';
import { setTenant } from 'store/slices/tenant';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
    const { isLoggedIn, shouldSkipSetupMFA } = useAuth();
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const tenantName = localStorage.getItem('tenantPath') || `/${params.tenantName}`;
    const tenantId = localStorage.getItem('tenant_id');
    const storageshouldSkipSetupMFA = window.localStorage.getItem('shouldSkipSetupMFA');
    useEffect(() => {
        if (!shouldSkipSetupMFA && !storageshouldSkipSetupMFA) {
            navigate(`${tenantName}/setupmfa`, { replace: true });
        }
        if (!isLoggedIn) {
            navigate(`${tenantName}`, { replace: true });
        } else {
            dispatch(setTenant({ id: String(tenantId) }));
        }
    }, [isLoggedIn, navigate, tenantName, dispatch, tenantId, shouldSkipSetupMFA, storageshouldSkipSetupMFA]);

    return children;
};

export default AuthGuard;
