import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import { RouteObject } from 'react-router-dom';
import TenantValidatorProvider from 'components/providers/TenantValidatorProvider';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/Login3')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/Register3')));
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/ForgotPassword3')));
const SetUpMFA = Loadable(lazy(() => import('views/pages/authentication/SetUpMFA')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes: RouteObject = {
    path: '/:tenantName',
    element: (
        <NavMotion>
            <TenantValidatorProvider>
                <GuestGuard>
                    <MinimalLayout />
                </GuestGuard>
            </TenantValidatorProvider>
        </NavMotion>
    ),
    children: [
        {
            path: '',
            element: <AuthLogin />
        },
        {
            path: 'login',
            element: <AuthLogin />,
            index: true
        },
        { path: 'setupmfa', element: <SetUpMFA />, index: true },
        {
            path: 'register',
            element: <AuthRegister />,
            index: true
        },
        {
            path: 'forgot',
            element: <AuthForgotPassword />,
            index: true
        }
    ]
};

export default LoginRoutes;
