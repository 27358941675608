const colors = {
    light: {
        primary: {
            '200': '#8EB1FF',
            '800': '#1147FF',
            main: '#1D63FF',
            light: '#E4ECFF',
            dark: '#1A5BFF'
        },
        secondary: {
            '200': '#9A9A9E',
            '800': '#212227',
            main: '#34353C',
            light: '#E7E7E8',
            dark: '#2F3036'
        }
    },
    dark: {
        primary: {
            '200': '#8EB1FF',
            '800': '#1147FF',
            main: '#1D63FF',
            light: '#E4ECFF',
            dark: '#1A5BFF'
        },
        secondary: {
            '200': '#9A9A9E',
            '800': '#212227',
            main: '#34353C',
            light: '#E7E7E8',
            dark: '#2F3036'
        }
    }
};

export default colors;
