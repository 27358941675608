import React, { createContext, useCallback, useEffect, useReducer, useState } from 'react';

// third-party
import {
    CognitoUser,
    CognitoUserPool,
    CognitoUserSession,
    CognitoUserAttribute,
    AuthenticationDetails,
    ChallengeName
} from 'amazon-cognito-identity-js';
import dayjs from 'dayjs';
import * as CognitoSDK from '@aws-sdk/client-cognito-identity-provider';

// reducer - state management
import { LOGIN, LOGOUT, SAVE_FIREBASE_USER, SET_ASSOCIATE_SOFTWARE_TOKEN } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import { AWS_API } from 'config';
import { AWSCognitoContextType, InitialLoginContextProps } from 'types/auth';
import { isTokenExpired } from 'utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { IUser } from 'views/backoffice/users/types';

// constant
const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const awsAdminClient = new CognitoSDK.CognitoIdentityProviderClient({
    region: AWS_API.region,
    credentials: {
        accessKeyId: AWS_API.accessKeyId,
        secretAccessKey: AWS_API.secretAccessKey
    }
});

export const userPool = new CognitoUserPool({
    UserPoolId: AWS_API.poolId || '',
    ClientId: AWS_API.appClientId || ''
});

const setSession = (serviceToken?: string | null) => {
    if (serviceToken) localStorage.setItem('serviceToken', serviceToken);
    else localStorage.removeItem('serviceToken');
};

// ==============================|| AWS Cognito CONTEXT & PROVIDER ||============================== //
const AWSCognitoContext = createContext<AWSCognitoContextType | null>(null);

export const AWSCognitoProvider = ({ children }: { children: React.ReactElement }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const [recoverEmail, setRecoverEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [shouldSkipSetupMFA, setShouldSkipSetupMFA] = useState(false);
    const [shouldShowSkipMFA, setShouldShowSkipMFA] = useState(true);
    const [loggedUser, setLoggedUser] = useState(null as CognitoUser | null);
    const [loggedUserAuthData, setLoggedUserAuthData] = useState({ email: '', password: '' } as { email: string; password: string });

    const cleanLocalStorage = () => {
        localStorage.removeItem('backend_jwt');
        localStorage.removeItem('shouldSkipSetupMFA');
        localStorage.removeItem('associateSoftwareToken');
        localStorage.removeItem('userEmail');
    };

    const logout = useCallback(() => {
        const locationSplitted = window.location.pathname.split('/');
        const authRoutes = ['login', 'register', 'forgot', 'recover', 'reset', 'user-confirmation', 'change', 'configured', 'setupmfa'];
        const loggedInUser = userPool.getCurrentUser();
        if (loggedInUser) {
            setShouldSkipSetupMFA(false);
            setSession(null);
            cleanLocalStorage();
            dispatch({ type: LOGOUT });
            loggedInUser.signOut();
        }
        if (!authRoutes.some((route) => window.location.pathname.includes(route))) navigate(`/${locationSplitted[1]}`);
    }, [navigate]);

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                const backendToken = window.localStorage.getItem('backend_jwt');
                const tenantPath = window.localStorage.getItem('tenantPath');
                const associateSoftwareToken = window.localStorage.getItem('associateSoftwareToken');
                if (associateSoftwareToken) {
                    dispatch({
                        type: SET_ASSOCIATE_SOFTWARE_TOKEN,
                        payload: { isLoggedIn: true, associateSoftwareToken }
                    });
                }
                const pathName = window.location.pathname;
                if (serviceToken && !isTokenExpired(backendToken) && pathName.includes(String(tenantPath))) {
                    setSession(serviceToken);
                    loginWithBackendData();
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: {
                                name: 'Betty'
                            }
                        }
                    });
                } else {
                    if (pathName !== '/' && !pathName.includes(String(tenantPath)) && serviceToken) {
                        localStorage.setItem('forcelyLoggedOut', 'true');
                    }
                    logout();
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, [logout]);

    useEffect(() => {
        const backendToken = window.localStorage.getItem('backend_jwt');
        if (isTokenExpired(backendToken))
            dispatch({
                type: LOGOUT
            });
    }, [location]);

    const getUsersData = async (users: IUser[]) => {
        const commands = users.map(
            (user) =>
                new CognitoSDK.AdminGetUserCommand({
                    UserPoolId: AWS_API.poolId,
                    Username: user.email
                })
        );
        const promiseResult = await Promise.allSettled(commands.map((c) => awsAdminClient.send(c)));
        const cognitoUserData = promiseResult
            .filter((r) => r.status === 'fulfilled')
            .map((user) => {
                if (user.status === 'fulfilled') {
                    return user.value;
                }
                return null;
            });
        const usersMapped = users.map((user) => {
            const userFromCognito = cognitoUserData.find((cognitoData) =>
                cognitoData?.UserAttributes?.some(
                    (attr: { Name?: string; Value?: string }) => attr.Name === 'email' && attr.Value === user.email
                )
            );
            if (userFromCognito) {
                return { ...user, hasMFA: Object.keys(userFromCognito).includes('PreferredMfaSetting') };
            }
            return { ...user, hasMFA: false };
        });
        return usersMapped;
    };

    const timesMFASkipped = async () => {
        const comannd = new CognitoSDK.AdminGetUserCommand({
            UserPoolId: AWS_API.poolId,
            Username: loggedUserAuthData.email
        });

        const userData = await awsAdminClient.send(comannd);
        const timesSkipped =
            Number(
                userData?.UserAttributes?.find((attr: { Name?: string; Value?: string }) => attr.Name === 'custom:mfa_skipped')?.Value
            ) || 0;

        if (timesSkipped > 1000 && !loggedUserAuthData.email.includes('85623')) {
            setShouldShowSkipMFA(false);
        }
        return timesSkipped;
    };

    const incrementSkipTimes = async () => {
        const value = (await timesMFASkipped()) + 1;
        const setSkipTimesCommand = new CognitoSDK.AdminUpdateUserAttributesCommand({
            UserPoolId: AWS_API.poolId,
            Username: loggedUserAuthData.email,
            UserAttributes: [
                {
                    Name: 'custom:mfa_skipped',
                    Value: String(value)
                }
            ]
        });

        await awsAdminClient.send(setSkipTimesCommand);
    };
    const login = async (email: string, password: string, rememberMe: boolean = false, mfacode?: string) =>
        new Promise((res) => {
            const usr = new CognitoUser({
                Username: email,
                Pool: userPool
            });

            const authData = new AuthenticationDetails({
                Username: email,
                Password: password
            });

            setLoggedUser(usr);
            setLoggedUserAuthData({ email, password });

            usr.authenticateUser(authData, {
                onSuccess: async (session: CognitoUserSession) => {
                    // User doesn't have MFA enabled

                    setSession(session.getAccessToken().getJwtToken());
                    setRecoverEmail('');
                    setNewPassword('');
                    // Check if user has virtual MFA setted up
                    usr.listDevices(60, null, {
                        onSuccess: (result) => {
                            if (result.Devices.length === 0) {
                                // Get software token to generate QR Code
                                usr.associateSoftwareToken({
                                    associateSecretCode: (secretCode: string) => {
                                        localStorage.setItem('associateSoftwareToken', secretCode);
                                        dispatch({
                                            type: SET_ASSOCIATE_SOFTWARE_TOKEN,
                                            payload: {
                                                isLoggedIn: true,
                                                associateSoftwareToken: secretCode
                                            }
                                        });
                                    },
                                    onFailure: (err: any) => console.log('associateSoftwareToken error', err)
                                });
                            } else {
                                usr.getDevice({
                                    onSuccess: (getDeviceResult: any) => {
                                        // Check if device is older than 45 days
                                        const currentDate = dayjs();
                                        const deviceCreateDate = dayjs.unix(getDeviceResult.Device.DeviceCreateDate);
                                        const diffInDays = currentDate.diff(deviceCreateDate, 'days');
                                        if (diffInDays > 45) {
                                            usr.forgetDevice({
                                                onSuccess: (forgetDeviceResult) => {},
                                                onFailure: (err) => {
                                                    console.log(`call error:`, err);
                                                }
                                            });
                                        }
                                    },
                                    onFailure: (err) => {
                                        console.log(`call error:`, err);
                                    }
                                });
                                setShouldSkipSetupMFA(true);
                                localStorage.setItem('shouldSkipSetupMFA', 'true');
                            }
                        },
                        onFailure: (err) => {
                            console.log('listDevices error: ', err);
                        }
                    });

                    usr.getUserAttributes((err, attributes) => {
                        if (err || !attributes) {
                            console.log(err);
                            return;
                        }

                        // eslint-disable-next-line no-plusplus
                        for (let i = 0; i < attributes.length; i++) {
                            if (attributes[i].getName() === 'given_name') {
                                console.log(`Nombre del usuario actual: ${attributes[i].getValue()}`);
                                break;
                            }
                        }
                    });

                    res(false);

                    localStorage.setItem('userEmail', authData.getUsername());

                    dispatch({
                        type: SAVE_FIREBASE_USER,
                        payload: {
                            isLoggedIn: false,
                            user: {
                                id: session.getIdToken().payload.sub,
                                email: authData.getUsername()
                                // name: authData.getValidationData() || 'John Doe'
                            }
                        }
                    });
                },
                onFailure: (_err: any) => {
                    res({ error: _err });
                },
                newPasswordRequired: (_userAttributes: unknown, _requiredAttribute: unknown) => {
                    /*
                    User was signed up by an admin and must provide new
                    password and required attributes, if any, to complete
                    authentication.
                    the api doesn't accept this field back
                    delete userAttributes.email_verified;
                    unsure about this field, but I don't send this back
                    delete userAttributes.phone_number_verified;
                    Get these details and call
                    usr.completeNewPasswordChallenge(password, userAttributes, requiredAttributes);
                    */
                },
                totpRequired: (challengeName: ChallengeName, challengeParameters: any) => {
                    if (!mfacode) {
                        res({ totpRequired: true });
                    } else {
                        loggedUser?.sendMFACode(
                            mfacode,
                            {
                                onSuccess: (session: CognitoUserSession) => {
                                    setShouldSkipSetupMFA(true);
                                    localStorage.setItem('shouldSkipSetupMFA', 'true');
                                    setSession(session.getAccessToken().getJwtToken());
                                    setRecoverEmail('');
                                    setNewPassword('');
                                    res({ success: true });

                                    if (rememberMe) {
                                        loggedUser.setDeviceStatusRemembered({
                                            onSuccess: (result) => {},
                                            onFailure: (err) => {
                                                console.log('setDeviceStatusRemembered error: ', err);
                                            }
                                        });
                                    }

                                    localStorage.setItem('userEmail', authData.getUsername());

                                    dispatch({
                                        type: SAVE_FIREBASE_USER,
                                        payload: {
                                            isLoggedIn: false,
                                            user: {
                                                id: session.getIdToken().payload.sub,
                                                email: authData.getUsername()
                                            }
                                        }
                                    });
                                },
                                onFailure: (_err: any) => {
                                    res({ error: _err });
                                }
                            },
                            'SOFTWARE_TOKEN_MFA'
                        );
                    }
                }
            });
        });

    const loginWithBackendData = () => {
        dispatch({
            type: LOGIN
        });
    };

    const register = (email: string, password: string, firstName: string, lastName: string) =>
        new Promise((success, rej) => {
            userPool.signUp(
                email,
                password,
                [
                    new CognitoUserAttribute({ Name: 'email', Value: email }),
                    new CognitoUserAttribute({ Name: 'name', Value: firstName }),
                    new CognitoUserAttribute({ Name: 'family_name', Value: lastName })
                ],
                [],
                async (err: unknown, result: unknown) => {
                    if (err) {
                        rej(err);
                        return;
                    }
                    success(result);
                }
            );
        });

    const recover = async (email: string = recoverEmail) =>
        new Promise((res, rej) => {
            const usr = new CognitoUser({
                Username: email,
                Pool: userPool
            });

            usr.forgotPassword({
                onSuccess(result) {
                    setRecoverEmail(email);
                    res(result);
                    console.log(`call result:`, result);
                },
                onFailure(err) {
                    rej(err);
                }
            });
        });

    const resetPassword = async (verificationCode: any, password: string) => {
        const usr = new CognitoUser({
            Username: recoverEmail,
            Pool: userPool
        });

        return new Promise<void>((resolve, reject) => {
            usr.confirmPassword(verificationCode, password, {
                onFailure(err) {
                    reject(err);
                },
                onSuccess() {
                    setNewPassword(password);
                    resolve();
                }
            });
        });
    };

    const resendConfirmationCode = async (email: string) =>
        new Promise((res, rej) => {
            const userData = {
                Username: email,
                Pool: userPool
            };

            const cognitoUser = new CognitoUser(userData);

            cognitoUser.resendConfirmationCode((err, result) => {
                if (err) {
                    rej(new Error(err.message));
                    console.log(err.message || JSON.stringify(err));
                }
                console.log(`call result: ${result}`);
                res(result);
            });
        });

    const confirmUser = async (email: string, code: string) =>
        new Promise((res, rej) => {
            const userData = {
                Username: email,
                Pool: userPool
            };

            const cognitoUser = new CognitoUser(userData);

            cognitoUser.confirmRegistration(code, true, (err, result) => {
                if (err) {
                    rej(err.message);
                }
                res(result);
                console.log(`confirmation User result: ${result}`);
            });
        });
    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <AWSCognitoContext.Provider
            value={{
                ...state,
                getUsersData,
                login,
                logout,
                register,
                loginWithBackendData,
                recover,
                recoverEmail,
                newPassword,
                resetPassword,
                resendConfirmationCode,
                confirmUser,
                shouldSkipSetupMFA,
                setShouldSkipSetupMFA,
                loggedUser,
                loggedUserAuthData,
                incrementSkipTimes,
                shouldShowSkipMFA,
                setShouldShowSkipMFA,
                timesMFASkipped
            }}
        >
            {children}
        </AWSCognitoContext.Provider>
    );
};

export default AWSCognitoContext;
