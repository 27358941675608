import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */
export const QUERY_GET_MENU_ITEMS = gql`
    query SaasMenu {
        SaasMenu {
            id
            name
            tag
            route
            order
            application
            enabled
            appSections {
                id
                name
                tag
                route
                enabled
                menuItems {
                    id
                    name
                    application
                    route
                    enabled
                    hasRecordTypes
                    recordType {
                        id
                        name
                    }
                }
            }
        }
    }
`;
